<template>
  <!--
    The view for Outbox-component.
    Used to display Outbox.
  -->
  <Portlet
    title="Outbox"
    icon="envelope-open"
  >
    <Outbox />
  </Portlet>
</template>

<script>
export default {
  name: "OutboxView",
  components: {
    Outbox: () => import('@/components/History/Outbox.vue')
  },
  metaInfo () {
    return {
      title: 'Outbox'
    }
  }
}
</script>